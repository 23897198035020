import React from "react"
import styled from "styled-components"
import BodyWrapper from "../components/BodyWrapper"
import GImg from "gatsby-image"

import {
  twoWaysWaffel,
  waffelBottom,
  topWaffel,
  waffleDancing,
  waffleGif,
  calloutWaffel,
} from "../utils/imageUpload.js"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
const WaffleParty = ({ data }) => {
  const [banner] = data.allFile.edges.map(({ node }) => node)

  return (
    <Layout>
      <SEO title="Popbar Belgian Boys" />
      <Landing>
        {" "}
        <GImg fluid={banner.childImageSharp.fluid} />
      </Landing>
      <Info>
        <BodyWrapper>
          <div className="wrapper">
            <div className="main-info">
              <img src={waffleGif} alt="" />
              <p>
                It's back to school! And whatever that looks like for your
                favorite classroom, Chubby and Skinny and the Belgian Boys team
                want to help them kick off a new school year the best way we
                know how: With WAFFLES!
                <b>
                  {" "}
                  We're throwing one lucky classroom a full-blown waffle party,{" "}
                </b>
                with generous servings of our individually-wrapped
                <Link to="/product/original-belgian-waffle">
                  <span> Original Belgian Waffles </span>
                </Link>
                and{" "}
                <Link to="/product/chocolate-coated-belgian-waffle">
                  <span> Choco Belgian Waffles </span>
                </Link>
                for everyone in the class.
              </p>
            </div>
            <div className="button-margin" style={{ textAlign: "center" }}>
              <p className="blue ">
                Whether they're on a screen, or inside a school, we'll make sure
                there's a reason to celebrate! As a special bonus, the Belgian
                Boys team will join the party virtually to wish everyone a great
                start to a new year! The contest closes September 7, so hurry
                and enter your favorite classroom today.{" "}
                <span> #btswaffleparty </span>
              </p>
              <Link to="/product/original-belgian-waffle">
                <img
                  className="button-margin"
                  style={{ width: "60%" }}
                  src={topWaffel}
                  alt=""
                />
              </Link>
              <img style={{ width: "50%" }} src={calloutWaffel} alt="" />
            </div>
            <div className="button-margin" style={{ textAlign: "center" }}>
              <img style={{ width: "100%" }} src={twoWaysWaffel} alt="" />
            </div>

            <div className="available-container">
              <h1>JUST PICK ONE! OR DO BOTH AND GET DOUBLE THE ENTRIES!</h1>
              <p className="blue">
                <b>1.</b>
                Tag{" "}
                <a
                  target="_blank"
                  href="https://www.instagram.com/belgianboys/"
                >
                  <b style={{ color: "var(--darkBlue)" }}>@belgianboys </b>
                </a>
                on an Instagram post with the hashtag
                <b> #btswaffleparty </b>, telling us what classroom you are
                nominating and why!
              </p>
              <h3>—OR— </h3>
              <p className="blue">
                <b>2.</b> Fill out the form below with details on the classroom
                you are nominating!
              </p>
              <div className="button-margin">
                <a
                  target="_blank"
                  className="button-pink"
                  href="https://forms.gle/1uLBJvBEWtMFFURW6"
                >
                  Enter to win
                </a>
              </div>
              <div className="button-margin">
                <img style={{ width: "100%" }} src={waffleDancing} alt="" />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <h1
                className="button-margin"
                style={{ color: "var(--darkBlue)", fontSize: "3rem" }}
              >
                CONTEST DETAILS
              </h1>
              <p className="blue button-margin">
                Belgian Boys will award each of the winning classroom's students
                a box of individually-wrapped, 5 count Original Belgian Waffle.
                Each entry must be made by a point of contact 18 years of age or
                older who can obtain official school permission for the Waffle
                Party, and who can liaise with the teacher to arrange shipment
                details and a time for the party.
              </p>
              <p className="blue ">
                <b>
                  Contest entries close at 11:59 Eastern Time on September 7,
                  2020{" "}
                </b>
                and a winner will be announced over Instagram on September 10.
                Our Original Belgian Waffles and Choco Belgian Waffles do not
                contain nuts, and are produced in a nut-free facility.
              </p>
            </div>
            <div className="button-margin">
              <Link className="button" to="/product/original-belgian-waffle">
                SHOP ORIGINAL BELGIAN WAFFLES
              </Link>
            </div>
            <div className="stroop">
              <img src={waffelBottom} alt="" />
            </div>
          </div>
        </BodyWrapper>
      </Info>
    </Layout>
  )
}

export default WaffleParty

const Landing = styled.div`
  min-height: 70vh;

  /* padding: 20px 0; */

  background-color: #9a51df;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  img {
    width: 80%;
    object-fit: fill;
  }
  @media (max-width: 769px) {
    min-height: 30vh;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1500px) {
    min-height: 50vh;
  }
`
const Info = styled.div`
  padding-top: 50px;
  background: var(--beige);
  a {
    text-decoration: none;
  }
  span {
    color: #00c1de;
    font-weight: 600;
  }

  .main-info {
    display: grid;
    align-items: center;
    justify-content: center;

    grid-gap: 5rem;
    grid-template-columns: repeat(auto-fit, 400px);

    @media (max-width: 900px) {
      grid-template-columns: repeat(auto-fit, 300px);
    }
    color: var(--darkBlue);

    img {
      width: 100%;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      /* text-align: center; */
    }
  }

  .blue {
    span {
      color: var(--pink);
    }
    text-align: center;
    color: var(--darkBlue);
    font-weight: 500;
    font-size: 20px;
    /* text-align: center; */
  }

  .available-container {
    margin-top: 100px;
    text-align: center;
    h1 {
      margin-bottom: 35px;
      color: var(--pink);
    }

    h3 {
      color: var(--darkBlue);
      font-weight: 600;
      margin: 35px 0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 200px);
    @media (max-width: 900px) {
      grid-template-columns: repeat(auto-fit, 150px);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, auto);
    }
    grid-gap: 2rem;
    margin-bottom: 100px;
    justify-content: center;

    a {
      text-decoration: none;
      :hover {
        text-decoration-line: underline;
        color: var(--darkBlue);
      }
    }
    p {
      font-weight: 600;
      color: var(--darkBlue);
    }

    .light {
      font-weight: 300;
    }
  }

  .button {
    margin: 40px 0;
    /* padding: 20px; */
    background-color: var(--darkBlue);
    @media (max-width: 700px) {
      width: 70%;
    }
    width: 40%;

    color: white;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
    border: none;
    margin-bottom: 5rem;
    text-decoration: none;

    padding: 20px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    :hover {
      background: var(--pink);
    }
  }

  .button-pink {
    /* padding: 20px; */
    background-color: var(--pink);
    @media (max-width: 700px) {
      width: 50%;
    }
    width: 20%;

    color: white;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
    border: none;
    margin-bottom: 5rem;
    text-decoration: none;

    padding: 20px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    :hover {
      background: var(--darkBlue);
    }
  }
  .stroop {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
      height: 50%;
    }
  }

  .button-margin {
    margin: 50px 0;
  }

  .wrapper {
    margin: 0 auto;
    max-width: var(--hugeInNav);

    @media (max-width: 1200px) {
      margin: 0 auto;
      max-width: var(--bigWidth);
    }

    @media (max-width: 1000px) {
      margin: 0 auto;
      max-width: var(--laptopWidth);
    }

    @media (max-width: 900px) {
      margin: 0 auto;
      /* max-width: var(--tabletWidth); */
      max-width: var(--smallLaptopWidth);
    }

    @media (max-width: 700px) {
      margin: 0 auto;
      max-width: var(--tabletWidth);
    }

    @media (max-width: 600px) {
      margin: 0 auto;
      max-width: var(--phoneWidth);
    }
  }
`

const Locations = [
  {
    name: "CHARLOTTE, NC",
    link: "https://www.pop-bar.com/pages/charlotte-nc",
  },
  {
    name: "CONCORD MILLS, NC",
    link: "https://www.pop-bar.com/pages/concord-mills-nc",
  },
  { name: "ANAHEIM, CA", link: "https://www.pop-bar.com/pages/anaheim-ca" },
  {
    name: "RANCHO CUCAMONGA, CA",
    link: "https://www.pop-bar.com/pages/rancho-cucamonga-ca",
  },

  {
    name: "SACRAMENTO, CA",
    link: "https://www.pop-bar.com/pages/sacramento-ca",
  },

  {
    name: "HUNTINGTON BEACH, CA",
    link: "https://www.pop-bar.com/pages/huntington-beach-ca",
  },

  {
    name: "CONCORD, CA",
    link: "https://www.pop-bar.com/pages/concord-ca",
  },

  {
    name: "LONG BEACH, CA",
    link: "https://www.pop-bar.com/pages/long-beach-ca",
  },

  {
    name: "ARCADIA, CA",
    link: "https://www.pop-bar.com/pages/arcadia-ca",
  },

  {
    name: "ORLANDO, FL",
    sub: "(Vineland Premium Outlets)",
    link: "https://www.pop-bar.com/pages/orlando-fl-vineland-premium-outlets",
  },
  {
    name: "ORLANDO, FL",
    sub: "(FL International Premium Outlets)",
    link:
      "https://www.pop-bar.com/pages/orlando-fl-international-premium-outlets",
  },
]

export const query = graphql`
  query WafflepartyPage {
    allFile(
      filter: {
        sourceInstanceName: { eq: "pagebanners" }
        relativeDirectory: { eq: "waffleparty" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
